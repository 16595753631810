import { Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux'

import { IsAuthSelector } from '../dux/selectors/auth.selector'

function PublicRoute({ children }) {
  const isAuthenticated = useSelector(state => IsAuthSelector(state))
  return isAuthenticated ? <Navigate to='/' /> : children
}

export default PublicRoute 