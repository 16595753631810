import { configureStore } from '@reduxjs/toolkit'
import { persistReducer, persistStore } from 'redux-persist'
import localForage from 'localforage'

import rootReducer from '../dux/reducers'

const persistConfig = {
  key: 'clothes.miduhouse',
  storage: localForage,
  blacklist: []
}

const getRootReducer = () => persistReducer(persistConfig, rootReducer)

export function configureStoreAndPersistor() {
  const store = configureStore({
    reducer: getRootReducer(),
    middleware: getDefaultMiddleware => getDefaultMiddleware({
      serializableCheck: {
        // Ignore these paths in the state
        ignoredPaths: ['register'],
        // Ignore specific actions
        ignoredActions: ['persist/PERSIST'],
      },
    }),
    devTools: process.env.NODE_ENV !== 'production',
  })

  const persistor = persistStore(store)

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('../dux/reducers', () => {
      console.log('Replacing reducers...')
      store.replaceReducer(getRootReducer())
    })
  }

  return { store, persistor }
}