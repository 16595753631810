import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../constants'

const initialAuthState = {
  isAuthenticated: false
}

export const authReducer = (state = initialAuthState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        ...action.payload,
      }
    case LOGOUT_SUCCESS:
      return initialAuthState
    default:
      return state
  }
}