import { signInWithPopup, signOut } from 'firebase/auth'

import { auth, googleProvider } from '../../google/firebase'
import { environment } from '../../helpers/environment.helper'
import { LOGIN_SUCCESS, LOGOUT_SUCCESS } from '../constants'

export const loginSuccess = (data) => ({
  type: LOGIN_SUCCESS,
  payload: data,
})

export const logoutSuccess = () => ({
  type: LOGOUT_SUCCESS,
})

export const loginWithGoogle = () => {
  return async dispatch => {
    try {
      const result = await signInWithPopup(auth, googleProvider)
      const idToken = await result.user.getIdToken()
      const data = await exchangeTokenAsync(idToken)
      await handleNewTokenAsync(data, dispatch)
    } catch (error) {
      console.error('Login failed:', error)
    }
  }
}

const handleNewTokenAsync = async ({ profile, token }, dispatch) => {
  if (!token) {
    await signOut(auth)
    throw new Error('Email not authorized')
  }
  else {
    dispatch(loginSuccess({
      isAuthenticated: true,
      token,
      profile
    }))
  }
}

const exchangeTokenAsync = async firebaseToken => {
  const res = await fetch(`${environment.apiUrl}/api/auth/google`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ token: firebaseToken }),
  })

  const data = (await res.json()) || {}
  return data
}

export const logout = () => {
  return async dispatch => {
    try {
      await signOut(auth)
      dispatch(logoutSuccess())
    } catch (error) {
      console.error('Logout failed:', error)
    }
  }
}

export const renewToken = () => {
  return async dispatch => {
    auth.currentUser && auth.currentUser.getIdToken(true)
    .then(token => {
      exchangeTokenAsync(token)
      .then(data => {
        handleNewTokenAsync(data, dispatch)
      })
    })
    .catch(error => {
      console.error('Error refreshing token:', error)
    })
  }
}