import { useSelector } from 'react-redux'
import { Navigate } from 'react-router-dom'

import { IsAuthSelector } from '../dux/selectors/auth.selector'


function PrivateRoute({ children }) {
  const isAuthenticated = useSelector(state => IsAuthSelector(state))
  
  return isAuthenticated ? children : <Navigate to='/login' />
}

export default PrivateRoute